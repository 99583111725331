(function() {
    angular.module('EntrakV5').controller('dashboardController', dashboardController);

    function dashboardController($scope, $rootScope, $interval, $timeout, KEY, Service, Api, APIKEY) {
        console.log('dashboardController');

        var caller = Api.createApiCaller();

    /* first load */
        var isFirefox = navigator.userAgent.indexOf("Firefox") != -1;
        $scope.clock = new Date();
        $scope.currentWsId = false;    //init to false, prevent ui showing blue header at the beginning
        $scope.currentSchedule = [];
        $scope.floorMap = {};
        $scope.nodeMap = {};
        $scope.controllableWsIdList = []; //id for all controllable ws
        $scope.rmIdList = [];    //id list for all retrieved room object, for ngRepeat use
        $scope.visitors = [];
        $scope.userMap = {};  //for displaying xxxx's workstation

        var recentIds = [];
        $scope.recentIdsDisplay = [];    //for display only, 2d array, only for ngRepeat

        $scope.todayWeather = {};
        $scope.iaqData = {};
        $scope.hasWeatherCard = false;
        
        $scope.dayNameMap = {};
        APIKEY.days.forEach(function(v, i){
            $scope.dayNameMap[v] = window.kendo.cultures.current.calendar.days.namesAbbr[i];
        });
        
        $scope.slickDataLoaded = false;//trigger re-init slick when change from false to true
        $scope.loadingRooms = false;
        $scope.loadingVisitors = false;
        $scope.loadingAvailableWorkstations = false;
        $scope.loadingControllableWorkstations = false;
        $scope.resettingScene = false;

        $rootScope.loadingPage = 0;
        var rmRefreshCount = 0;
        var recentRefreshCount = 0;

        function processWorkstation(ws){
            //show extend btn for current user's ws
            if (ws.id === $scope.currentWsId){
                if (Service.isCheckedIn(ws)){
                    if (ws.schedule.nextOffAt){
                        var diff = Service.getMinuteDiff(ws.schedule.nextOffAt);
                        ws.showExtend = diff != null && diff < KEY.minBeforeShowExtend;
                    } else {
                        ws.showExtend = false;
                    }
                } else {
                    ws.showExtend = false;
                }
            }
            //update ws fields
            Service.updateWsName(ws, $scope.userMap);
            // Service.setNodeAllDisconnected(ws);
        }
        function processDetailWorkstation(ws) {
          processWorkstation(ws);
          Service.updateDimLv(ws);
          processNodeScenes(ws);
        }

        function processRoom(rm){
            //show extend btn
            if (Service.isCheckedIn(rm)){
                if (rm.schedule.nextOffAt){
                    var diff = Service.getMinuteDiff(rm.schedule.nextOffAt);
                    rm.showExtend = diff != null && diff < KEY.minBeforeShowExtend;
                    // rm.offMinutes = Service.numFmt(diff);
                } else {
                    rm.showExtend = false;
                }
            } else {
                rm.showExtend = false;
            }
            // Service.setNodeAllDisconnected(rm);
        }
        function processDetailRoom(rm) {
          processRoom(rm);
          Service.updateDimLv(rm);
          processNodeScenes(rm);
        }

        $scope.processRecentControl = function(){
            $scope.slickDataLoaded = false;
            $timeout(function(){
                $scope.recentIdsDisplay = [];
                for (var i=0; i<recentIds.length; i++){
                    if (i % 2 == 0)
                        $scope.recentIdsDisplay.push([]);
                    $scope.recentIdsDisplay[$scope.recentIdsDisplay.length-1].push(recentIds[i]);
                }
                $scope.slickDataLoaded = true;
                if (isFirefox){
                    $timeout(function(){//workaround firefox bug
                        var h = 0;
                        var slick = $("slick");
                        slick.siblings().each(function(){
                            h += $(this).height();
                        });
                        slick.css({'max-height': 'calc(100% - 20px - 20px - ' + h + 'px)'});
                    });
                }
            });
        }

        function processCurrentWorkstation(ws){
            if (ws){
                $scope.currentWsId = ws.id;
                processDetailWorkstation(ws);
                $scope.nodeMap[ws.id] = ws;
                if ($scope.controllableWsIdList.indexOf(ws.id) === -1)
                    $scope.controllableWsIdList.push(ws.id);
                if (ws.timetableId){
                    $rootScope.loadingPage++;
                    caller.call(Api.getSchedule(ws.timetableId)).then(function(sch){
                        var prevDay = null;
                        $scope.currentSchedule = [];
                        Service.sortSchTimeslot(sch);
                        sch.timeslots.forEach(function(ts){
                            if (ts.weekday != prevDay){
                                prevDay = ts.weekday;
                                $scope.currentSchedule.push({
                                    weekday: prevDay,
                                    lbl: Service.timeslotTimeToStr(ts.start) + "-" + Service.timeslotTimeToStr(ts.end)
                                });
                            } else {
                                $scope.currentSchedule[$scope.currentSchedule.length-1].lbl += " & " + Service.timeslotTimeToStr(ts.start) + "-" + Service.timeslotTimeToStr(ts.end);
                            }
                        });
                        $rootScope.loadingPage--;
                    }, function(err){
                        if (err === KEY.ignore)
                            return;
                        $scope.currentSchedule = [];
                        $rootScope.generalErrorHandler();
                    });
                }
            } else {
                $scope.currentWsId = null;
            }            
        }

        $scope.getCurrentWorkstation = function(){
            $rootScope.loadingPage++;
            return caller.call(Api.getCurrentWorkstation()).then(function(res){
                //TODOricky uncomment below to enable weather card
                // if (res && res.count.aircon + res.count.iaq > 0){
                //     $scope.hasWeatherCard = true;
                //     $scope.getWeatherData(res.cityId); ###cityId removed from query###
                //     $scope.getSensorData(res.id);
                // } else {
                //     $scope.hasWeatherCard = false;
                // }
                processCurrentWorkstation(res);
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
        }

        $scope.getAvailableWorkstations = function(callback){
            $scope.loadingAvailableWorkstations = true;
            $rootScope.loadingPage++;
            caller.call(Api.getAvailableWorkstations()).then(function(res){//this doesnt include currentWs
                if (typeof callback === 'function')
                    callback(res);
                $scope.loadingAvailableWorkstations = false;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.loadingAvailableWorkstations = false;
                $rootScope.generalErrorHandler();
            });
        }
        $scope.getControllableWorkstations = function(callback){
            $scope.loadingControllableWorkstations = true;
            $rootScope.loadingPage++;
            caller.call(Api.getControllableWorkstations()).then(function(res){
                if ($scope.currentWsId)//dont overwrite currentWs, this one has less info than currentWs
                    Service.deleteArrItem(res, $scope.currentWsId);
                $scope.controllableWsIdList = res.map(function(node){
                    processWorkstation(node);
                    return node.id;
                });
                if ($scope.currentWsId)
                    $scope.controllableWsIdList.push($scope.currentWsId);
                Service.arrayToMap(res, "id", $scope.nodeMap);
                $scope.loadingControllableWorkstations = false;
                $rootScope.loadingPage--;
                if (typeof callback === 'function')
                    callback();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.loadingControllableWorkstations = false;
                $rootScope.generalErrorHandler();
            });
        }

        $scope.getRecentUsed = function(){
            $rootScope.loadingPage++;
            caller.call(Api.getRecentUsed()).then(function(res){
                res = res.recentUsedNodes.filter(function(r){
                  return r.nodeId !== $scope.currentWsId;
                });
                var recentNodes = res.sort(function(a, b){
                    return b.lastUsedTime - a.lastUsedTime;
                });
                recentNodes = recentNodes.map(function(n){
                  if (n.workstation) {
                    processWorkstation(n.workstation);
                    return n.workstation;  
                  } else {
                   processRoom(n.room);
                   return n.room;
                  }
                });
                Service.arrayToMap(recentNodes, "id", $scope.nodeMap);
                recentIds = recentNodes.slice(0, KEY.recentControlListLength).map(function(n){
                    return n.id;
                });
                $scope.processRecentControl();
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
        }

        $scope.getAllRooms = function(){
            $scope.loadingRooms = true;
            $rootScope.loadingPage++;
            caller.call(Api.getAllRooms()).then(function(res){
                $scope.rmIdList = res.map(function(node){
                    var tmp = $scope.nodeMap[node.id];
                    if (tmp && tmp.count) {//dont overwrite detailStatusRoom by simpleStatusRoom
                        tmp.status = node.status;
                        tmp.schedule = node.schedule;
                    } else {
                        processRoom(node);
                        $scope.nodeMap[node.id] = node;
                    }
                    return node.id;
                });
                $scope.loadingRooms = false;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.loadingRooms = false;
                $rootScope.generalErrorHandler();
            });
        }

        //TODOricky uncomment below to enable weather card
        // $scope.getWeatherData = function(cityId){
        //     $rootScope.loadingPage++;
        //     caller.call(Api.getWeather(cityId)).then(function(res){
        //         processWeatherData(res);
        //         $rootScope.loadingPage--;
        //     }, function(err){
        //         if (err === KEY.ignore)
        //             return;
        //         $rootScope.generalErrorHandler();
        //     });
        // }

        $scope.getSensorData = function(id){
            $rootScope.loadingPage++;
            caller.call(Api.getSensorData(id, true)).then(function(res){
                processSensorData(res);
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
        }

        function processNodeScenes(node){
            $rootScope.getTenantId().then(function(){
                node.scenes = [];
                var scenes = $rootScope.currentUser.tenant.scenes;
                scenes.forEach(function(s){
                    var sceneDeviceMap = Service.arrayToMap(s.configs, 'deviceId');
                    for (var i=0; i<node.deviceIds.length; i++){
                        if (sceneDeviceMap[node.deviceIds[i]]){
                            node.scenes.push(s);
                            break;
                        }
                    }
                });
            });
        }

        $scope.getVisitors = function(){
            $scope.loadingVisitors = true;
            $rootScope.loadingPage++;
            caller.call(Api.getVisitors(Service.getUnixTimestamp(new Date()))).then(function(res){
                $scope.visitors = res;
                $scope.loadingVisitors = false;
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.loadingVisitors = false;
                $rootScope.generalErrorHandler();
            });
        }

        $scope.getFloors = function(tId){
            $rootScope.loadingPage++;
            caller.call(Api.getFloorList(tId)).then(function(res){
                $scope.floorMap = Service.arrayToMap(res.zones);
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
        }

        $scope.getUsers = function(tId) {
            $rootScope.loadingPage++;
            caller.call(Api.getProfiles(tId, 0)).then(function(res){
                $scope.userMap = Service.arrayToMap(res);
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
        }

        $scope.refreshData = function(){
            var p = $scope.getCurrentWorkstation();
            rmRefreshCount--;
            recentRefreshCount--;
            if (rmRefreshCount < 1){
                $scope.getAllRooms();
                rmRefreshCount = 5;
            }
            if (recentRefreshCount < 1){
                p.then($scope.getRecentUsed);
                recentRefreshCount = 2;
            }
            $scope.clock = new Date();
        }
        $rootScope.getTenantId().then(function(tId){
            $scope.getUsers(tId);
            $scope.getFloors(tId);
            $scope.getVisitors();
            $scope.refreshData();
        });
    /* first load */

    /* header */
        $scope.currentWsActionPopupOpt = {
            anchor: $("#dashboard #currentWsActionBtn"),
            origin: "bottom right",
            position: "top right",
        }

        $scope.closeCurrentWsActionPopup = function(){
            $scope.currentWsActionPopup.close();
        }

        $scope.schedulePopupOpt = {
            anchor: $("#dashboard .scheduleIcon"),
            origin: "top right",
            position: "top left",
        }
    /* header */

    /* select ws */
        $scope.openSelectWsWin = function(){
            $scope.getAvailableWorkstations(function(list){
                //this btn shows only when curUser dont have ws, so curWs is always null here
                $rootScope.showSelectWsWin(list, null, Service.translate("dashboard.panel.selectWsReminder"), processCurrentWorkstation);
            });
        }
        $scope.openSelectHotDeskWin = function(){
            $scope.closeCurrentWsActionPopup();
            $scope.getAvailableWorkstations(function(list){
                list.filter(ws => ws.isHotDesk);
                //this btn shows only when curUser HAVE ws assigned, so curWs is always NOT null here
                $rootScope.showSelectWsWin(list, $scope.nodeMap[$scope.currentWsId], Service.translate("dashboard.panel.switchWsReminder"), processCurrentWorkstation);
            });
        }
    /* select ws */

    /* general control */
        //process and store node response
        function updateRecentIds(lastClickId){
            if (lastClickId !== $scope.currentWsId){
              var i = recentIds.indexOf(lastClickId);
              if (i == -1){
                  recentIds.unshift(lastClickId);
                  recentIds = recentIds.slice(0, KEY.recentControlListLength);
              } else {
                  recentIds.splice(i, 1);
                  recentIds.unshift(lastClickId);
              }
            }
        }
        function processNodeActionRes(res){
            if (Service.isWorkstation(res)){
                processDetailWorkstation(res);
            } else {
                processDetailRoom(res);
            }
            updateRecentIds(res.id);
            $scope.processRecentControl();
            $scope.nodeMap[res.id] = res;
        }

        //action: APIKEY.action.checkin, APIKEY.action.checkout, APIKEY.action.extend
        function changeNodeStatus(node, action, duration, callback){
            node.isLoading = true;
            var isWs = Service.isWorkstation(node);
            var isTurnOffCurrentHotDesk = false;
            if (action === APIKEY.action.checkin){
                action = Api[isWs ? "turnOnWorkstation" : "turnOnRoom"](node.id, duration);
            } else if (action === APIKEY.action.extend){
                action = Api[isWs ? "extendWorkstation" : "extendRoom"](node.id, duration);
            } else if (action === APIKEY.action.checkout){
                action = Api[isWs ? "turnOffWorkstation" : "turnOffRoom"](node.id);
                if (node.isHotDesk && node.id === $scope.currentWsId)
                    isTurnOffCurrentHotDesk = true;
            } else {
                console.error("invalid action", action);
                return;
            }

            caller.call(action).then(function(res){
                if (isTurnOffCurrentHotDesk){//this may switch back to previous owned ws, need to get currentWs again
                    $scope.getCurrentWorkstation();
                }
                processNodeActionRes(res);
                if (typeof callback === 'function')
                    callback();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.showError(Service.translate("error.generalControlFail"));
            });
        }

        //click on/off btn
        $scope.toggleNode = function(node, checkin, collapseWhenOff){
            if (checkin){
                if (node.schedule.withIn){
                    changeNodeStatus(node, APIKEY.action.checkin);
                } else {
                    $scope.showTimerWin(node, false);
                }
            } else {
                changeNodeStatus(node, APIKEY.action.checkout, null, (collapseWhenOff ? function(){
                    $scope.expandedId = null;
                    $scope.closeCurrentWsActionPopup();
                } : null));
            }
        }

        //$value: 0 - 100
        $scope.changeNodeDimLv = function(node, $value, callback){
            node.isLoading = true;
            if (Service.isWorkstation(node)){
                var action = Api.changeWorkstationCondition(node.id, null, $value);
            } else {
                var action = Api.changeRoomCondition(node.id, null, $value);
            }
            caller.call(action).then(function(res){
                processNodeActionRes(res);
                if (typeof callback === 'function')
                    callback();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.showError(Service.translate("error.generalControlFail"));
            });
        }

        //action: APIKEY.action.warmer, APIKEY.action.cooler
        $scope.changeNodeTemperature = function(node, action, callback){
            if (action === APIKEY.action.cooler){
                node.isCoolingTemp = true;
            } else if (action === APIKEY.action.warmer){
                node.isWarmingTemp = true;
            } else {
                console.error("invalid action", action);
                return;
            }

            var isWs = Service.isWorkstation(node);
            caller.call(Api[isWs ? "changeWorkstationCondition" : "changeRoomCondition"](node.id, action)).then(function(res){
                processNodeActionRes(res);
                if (typeof callback === 'function')
                    callback();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.showError(Service.translate("error.generalControlFail"));
            });
        }

        $scope.activateScene = function(id){
            $rootScope.loadingPage++;
            var node = $scope.nodeForScene;
            caller.call(Api.activateScene(id)).then(function(){
                updateRecentIds(node.id);
                $scope.processRecentControl();
                $rootScope.loadingPage--;
                $scope.sceneWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.loadingPage--;
                $rootScope.showError(Service.translate("error.generalControlFail"));
            });
        }

        $scope.deactivateSceneForNode = function(){
            $scope.resettingScene = true;
            var node = $scope.nodeForScene;
            changeNodeStatus(node, APIKEY.action.checkin, null, function(){
                $scope.resettingScene = false;
                updateRecentIds(node.id);
                $scope.processRecentControl();
                $scope.sceneWin.close();
            });
        }
    /* general control */

    /* node cards */
        $scope.cardFilterOn = true;
        $scope.cardFilterOff = true;
        $scope.cardFilterName = '';
        $scope.expandedId = null;
        $scope.moreRmActionRmId = null;

        $scope.getDeviceCount = Service.getDeviceCount;

        $scope.appWinOpt = {
            width: "700px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            title: Service.translate("dashboard.card.downloadAppTitle"),
        }
        $scope.showAppWin = function(){
            setTimeout(function(){
                $scope.appWin.center().open();
            });
        }

        $scope.sceneWinOpt = {
            width: "600px",
            height: "400px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            title: Service.translate("dashboard.card.titleScene"),
        }
        $scope.showScenes = function(node){
            $scope.closeCurrentWsActionPopup();
            $scope.nodeForScene = node;
            $rootScope.resetColorIndex();
            $scope.nodeForScene.scenes.forEach(function(s){
                s.color = $rootScope.getNextColor();
            });
            setTimeout(function(){
                $scope.sceneWin.center().open();
            });
        }

        $scope.allWsWinOpt = {
            width: "900px",
            height: "595px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            title: Service.translate("dashboard.card.titleWs"),
        }
        $scope.showAllWorkstations = function(){
            $scope.controllableWsIdList = [];
            $scope.getControllableWorkstations();
            $scope.cardFilterOn = true;
            $scope.cardFilterOff = true;
            $scope.cardFilterName = '';
            $scope.expandedId = null;
            setTimeout(function(){
                $scope.allWsWin.center().open();
            });
        }
        
        $scope.getNodeDetail = function(node) {
          node.isLoading = true;
          var isWs = Service.isWorkstation(node);
          caller.call(isWs ? Api.getWorkstation(node.id) : Api.getRoom(node.id)).then(function(res){
                if (isWs) {
                  processDetailWorkstation(res);
                } else {
                  processDetailRoom(res);
                }
                $scope.nodeMap[res.id] = res;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.generalErrorHandler();
            });
        }
        $scope.expandCard = function(node){
            if ($scope.expandedId === node.id){
                $scope.expandedId = null;
            } else {
                $scope.getNodeDetail(node);
                $scope.expandedId = node.id;
            }
        }

        $scope.moreRmActionWinOpt = {
            width: "420px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
        }
        $scope.showMoreRmActionWin = function(rm){
            $scope.getNodeDetail(rm);
            $scope.moreRmActionRmId = rm.id;
            $scope.moreRmActionWin.title(rm.name);
            setTimeout(function(){
                $scope.moreRmActionWin.center().open();
            });
        }

        $scope.allRoomWinOpt = {
            width: "900px",
            height: "595px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            title: Service.translate("dashboard.card.titleRoom"),
        }
        $scope.showAllRooms = function(){
            $scope.cardFilterOn = true;
            $scope.cardFilterOff = true;
            $scope.cardFilterName = '';
            $scope.expandedId = null;
            setTimeout(function(){
                $scope.allRoomWin.center().open();
            });
        }

        $scope.filterByName = function(){
            var text = ($scope.cardFilterName ? $scope.cardFilterName.toLowerCase() : null);
            return function(item){
                item = $scope.nodeMap[item];
                if (text == null)
                    return true;
                return item.name && item.name.toLowerCase().indexOf(text) > -1;;
            }
        }

        $scope.slickConfig = {
            dots: true,
            infinite: true,
            draggable: false,
        }

        $scope.nameSort = function(id){
            return $scope.nodeMap[id].name;
        }
    /* node cards */

    /* visitor card */
        $scope.visitorData = {};
        $scope.visitorWinOpt = {
            width: "600px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false
        }
        var now = new Date();
        now.setHours(0, 0, 0, 0);
        $scope.datePickerOpt = {
            footer: false,
            format: "f",
            timeFormat: "T",
            min: now,
        }

        $scope.showInviteVisitor = function(){
            var now = new Date();
            now.setMinutes(0, 0, 0);
            $scope.visitorData.start = new Date(now);
            now.setDate(now.getDate()+1);
            $scope.visitorData.end = now;
            $scope.visitorData.email = '';
            $scope.visitorData.id = null;
            $scope.visitorData.accessCode = null;
            $scope.visitorWin.title(Service.translate("dashboard.card.inviteVisitor"));
            setTimeout(function(){
                $scope.visitorWin.center().open();
            });
        }
        $scope.inviteVisitor = function(){
            if (!Service.isEmail($scope.visitorData.email)){
                $rootScope.showError(Service.translate("error.invalidEmailFormat"));
                return;
            }
            $scope.visitorData.saving = true;
            $rootScope.loadingPage++;
            caller.call(Api.inviteVisitor($scope.visitorData.email, Service.getUnixTimestamp($scope.visitorData.start), Service.getUnixTimestamp($scope.visitorData.end))).then(function(res){
                $scope.visitors.push(res);
                $rootScope.loadingPage--;
                $scope.visitorData.saving = false;
                $scope.visitorWin.close();
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.loadingPage--;
                $rootScope.showError(Service.translate("error.generalControlFail"));
            });
        }
        $scope.editVisitor = function(visitor){
            $scope.visitorData.id = visitor.id;
            $scope.visitorData.start = Service.getDate(visitor.startTime);
            $scope.visitorData.end = Service.getDate(visitor.endTime);
            $scope.visitorData.email = visitor.email;
            $scope.visitorData.accessCode = visitor.accessCode;
            $scope.visitorWin.title(Service.translate("dashboard.card.visitorInfo"));
            setTimeout(function(){
                $scope.visitorWin.center().open();
            });
        }
        $scope.deleteVisitor = function(){
            var id = $scope.visitorData.id;
            $rootScope.deletePopup.show("dashboard.card.revokeVisitor", "dashboard.card.revokeDesc", $scope.visitorData.email, function(){
                caller.call(Api.revokeVisitor(id)).then(function(res){
                    Service.deleteArrItem($scope.visitors, id);
                    $rootScope.deletePopup.close();
                    $scope.visitorWin.close();
                }, function(err){
                    if (err === KEY.ignore)
                        return;
                    $rootScope.showError(Service.translate("error.generalDelItemFail"));
                });
            });
        }
    /* visitor card */

    /* weather card */
        function processWeatherData(res){
            switch(res.icon){
                case "CLEARDAY":
                case "CLEARNIGHT":
                    res.icon = "sunny";
                    break;
                case "PARTLYCLOUDYDAY":
                case "PARTLYCLOUDYNIGHT":
                    res.icon = "partlycloudy";
                    break;
                case "WIND":
                case "CLOUDY":
                    res.icon = "partlysunny";
                    break;
                case "RAIN":
                    res.icon = "rain";
                    break;
                case "SNOW":
                case "HAIL":
                    res.icon = "snow";
                    break;
                case "SLEET":
                    res.icon = "sleet";
                    break;
                case "FOG":
                    res.icon = "fog";
                    break;
                case "THUNDERSTORM":
                case "TORNADO":
                    res.icon = "tstorms";
                    break;
            }
            $scope.todayWeather = res;
        }
        function processSensorData(res){
            $scope.iaqData = {};
            if (res.iaqReading){
                res.iaqReading.forEach(function(data){
                    if (data.measurement === 'humidity'){
                        $scope.iaqData[data.measurement] = Service.numFmt(data.avg*100);
                    } else if (data.measurement === 'temperature'){
                        $scope.iaqData[data.measurement] = Service.numFmt(data.avg, 1);
                    } else {
                        $scope.iaqData[data.measurement] = Service.numFmt(data.avg);
                    }
                });
                $scope.iaqData.outdoorHI = res.heatIndex.outdoor + 1;
                $scope.iaqData.indoorHI = res.heatIndex.indoor == null ? null : res.heatIndex.indoor + 1;
            }
        }

        $scope.weatherTooltipOpt = {
            callout: false,
            position: "right",
            filter: ">div",
            width: "auto",
            position: "bottom",
            content: function(e) {
                return Service.translate("label." + e.target.data("tt"));
            },
            show: function(e) {
                this.refresh();
            }
        }
    /* weather card */

    /* timer window */
        $scope.timerData = {
            duration: $rootScope.defaultTimerDuration,
            nodeId: null,
            nodeNextOff: null,
            isExtend: false,
        }

        $scope.timerWinOpt = {
            width: "700px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            activate: function(){
                $scope.$apply(function(){
                    $scope.timerData.duration = $rootScope.defaultTimerDuration;
                    $scope.timerOnChange($scope.timerData.duration);
                    $scope.timerData.timerWinShowing = true;
                });
            },
            deactivate: function(){
                $scope.$apply(function(){
                    $scope.timerData.timerWinShowing = false;
                    $scope.timerData.nodeId = null;
                    $scope.timerData.isExtend = false;
                    $scope.timerData.nodeNextOff = null;
                });
            }
        }
        //click extend or on when outside schedule
        $scope.showTimerWin = function(node, isExtend){
            $scope.timerData.nodeId = node.id;
            $scope.timerData.nodeNextOff = Service.getDate(node.schedule.nextOffAt);
            $scope.timerData.isExtend = isExtend;
            $scope.timerWin.title(node.name);
            setTimeout(function(){
                $scope.timerWin.center().open();
            });
        }

        $scope.timerOnChange = function($value){
            $scope.timerData.timerOffTime = $scope.timerData.isExtend ? new Date($scope.timerData.nodeNextOff) : new Date();
            $scope.timerData.timerOffTime.setMinutes($scope.timerData.timerOffTime.getMinutes() + $value);
        }

        $scope.closeTimerWin = function(){
            $scope.timerWin.close();
        }

        $scope.timerUpdateOnTime = function(){
            var node = $scope.nodeMap[$scope.timerData.nodeId];
            if ($scope.timerData.isExtend){
                changeNodeStatus(node, APIKEY.action.extend, $scope.timerData.duration, $scope.closeTimerWin);
            } else {
                changeNodeStatus(node, APIKEY.action.checkin, $scope.timerData.duration, $scope.closeTimerWin);
            }
        }
    /* timer window */

        var timer = $interval($scope.refreshData, 1000 * 60);

        $scope.$on('$destroy', function() {
            console.log("dashboardController destroy");
            $interval.cancel(timer);
            caller.cancel();
        });
    }
})();
